export default {
  lineNumberColor: `#abb2bf`,
  lineNumberBgColor: `#282c34`,
  backgroundColor: `#282c34`,
  textColor: `#abb2bf`,
  substringColor: `#e06c75`,
  keywordColor: `#c678dd`,
  attributeColor: `#98c379`,
  selectorAttributeColor: `#e06c75`,
  docTagColor: `#c678dd`,
  nameColor: `#e06c75`,
  builtInColor: `#e6c07b`,
  literalColor: `#56b6c2`,
  bulletColor: `#61aeee`,
  codeColor: `#abb2bf`,
  additionColor: `#98c379`,
  regexpColor: `#98c379`,
  symbolColor: `#61aeee`,
  variableColor: `#d19a66`,
  templateVariableColor: `#d19a66`,
  linkColor: `#61aeee`,
  selectorClassColor: `#d19a66`,
  typeColor: `#d19a66`,
  stringColor: `#98c379`,
  selectorIdColor: `#61aeee`,
  quoteColor: `#5c6370`,
  templateTagColor: `#abb2bf`,
  deletionColor: `#e06c75`,
  titleColor: `#61aeee`,
  sectionColor: `#e06c75`,
  commentColor: `#5c6370`,
  metaKeywordColor: `#abb2bf`,
  metaColor: `#61aeee`,
  functionColor: `#abb2bf`,
  numberColor: `#d19a66`,
}
